export const appConstants = {
    CREATE_WISHLIST_REQUEST: "CREATE_WISHLIST_REQUEST",
    CREATE_WISHLIST_SUCCESS: "CREATE_WISHLIST_SUCCESS",
    CREATE_WISHLIST_FAILURE: "CREATE_WISHLIST_FAILURE",
    UPDATE_BUSINESS_PROFILE_REQUEST: "UPDATE_BUSINESS_PROFILE_REQUEST",
    UPDATE_BUSINESS_PROFILE_SUCCESS: "UPDATE_BUSINESS_PROFILE_SUCCESS",
    UPDATE_BUSINESS_PROFILE_FAILURE: "UPDATE_BUSINESS_PROFILE_FAILURE",
    REVERSE_LOCATE_REQUEST: "REVERSE_LOCATE_REQUEST",
    REVERSE_LOCATE_SUCCESS: "REVERSE_LOCATE_SUCCESS",
    REVERSE_LOCATE_FAILURE: "REVERSE_LOCATE_FAILURE",
    UPDATE_PERSONAL_PROFILE_REQUEST: "UPDATE_PERSONAL_PROFILE_REQUEST",
    UPDATE_PERSONAL_PROFILE_SUCCESS: "UPDATE_PERSONAL_PROFILE_SUCCESS",
    UPDATE_PERSONAL_PROFILE_FAILURE: "UPDATE_PERSONAL_PROFILE_FAILURE",
    FETCH_REFERRAL_WALLET_REQUEST: "FETCH_REFERRAL_WALLET_REQUEST",
    FETCH_REFERRAL_WALLET_SUCCESS: "FETCH_REFERRAL_WALLET_SUCCESS",
    FETCH_REFERRAL_WALLET_FAILURE: "FETCH_REFERRAL_WALLET_FAILURE",
    FETCH_PENDING_REFERRALS_REQUEST: "FETCH_PENDING_REFERRALS_REQUEST",
    FETCH_PENDING_REFERRALS_SUCCESS: "FETCH_PENDING_REFERRALS_SUCCESS",
    FETCH_PENDING_REFERRALS_FAILURE: "FETCH_PENDING_REFERRALS_FAILURE",
    FETCH_CONFIRMED_REFERRALS_REQUEST: "FETCH_CONFIRMED_REFERRALS_REQUEST",
    FETCH_CONFIRMED_REFERRALS_SUCCESS: "FETCH_CONFIRMED_REFERRALS_SUCCESS",
    FETCH_CONFIRMED_REFERRALS_FAILURE: "FETCH_CONFIRMED_REFERRALS_FAILURE",
    QUOTE_REQUEST_MODAL_OPENED: "QUOTE_REQUEST_MODAL_OPENED",
};
