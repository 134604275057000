export const authConstants = {
    FETCH_FF_PROFILE_REQUEST: "FETCH_FF_PROFILE_REQUEST",
    FETCH_FF_PROFILE_SUCCESS: "FETCH_FF_PROFILE_SUCCESS",
    FETCH_FF_PROFILE_FAILURE: "FETCH_FF_PROFILE_FAILURE",
    REGISTER_FF_REQUEST: "REGISTER_FF_REQUEST",
    REGISTER_FF_SUCCESS: "REGISTER_FF_SUCCESS",
    REGISTER_FF_FAILURE: "REGISTER_FF_FAILURE",
    VERIFY_BY_EMAIL_REQUEST: "VERIFY_BY_EMAIL_REQUEST",
    VERIFY_BY_EMAIL_SUCCESS: "VERIFY_BY_EMAIL_SUCCESS",
    VERIFY_BY_EMAIL_FAILURE: "VERIFY_BY_EMAIL_FAILURE",
    RESEND_VERIFY_CODE_REQUEST: "RESEND_VERIFY_CODE_REQUEST",
    RESEND_VERIFY_CODE_SUCCESS: "RESEND_VERIFY_CODE_SUCCESS",
    RESEND_VERIFY_CODE_FAILURE: "RESEND_VERIFY_CODE_FAILURE",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOG_OUT: "LOG_OUT",
    FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    RESET_PASSWORD_LOGGED_USER_REQUEST: "RESET_PASSWORD_LOGGED_USER_REQUEST",
    RESET_PASSWORD_LOGGED_USER_SUCCESS: "RESET_PASSWORD_LOGGED_USER_SUCCESS",
    RESET_PASSWORD_LOGGED_USER_FAILURE: "RESET_PASSWORD_LOGGED_USER_FAILURE",
    FETCH_TEAM_MEMBERS_REQUEST: "FETCH_TEAM_MEMBERS_REQUEST",
    FETCH_TEAM_MEMBERS_SUCCESS: "FETCH_TEAM_MEMBERS_SUCCESS",
    FETCH_TEAM_MEMBERS_FAILURE: "FETCH_TEAM_MEMBERS_FAILURE",
    DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
    DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
    DELETE_ACCOUNT_FAILURE: "DELETE_ACCOUNT_FAILURE",
    ADD_MEMBER_REQUEST: "ADD_MEMBER_REQUEST",
    ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
    ADD_MEMBER_FAILURE: "ADD_MEMBER_FAILURE",
    REMOVE_MEMBER_REQUEST: "REMOVE_MEMBER_REQUEST",
    REMOVE_MEMBER_SUCCESS: "REMOVE_MEMBER_SUCCESS",
    REMOVE_MEMBER_FAILURE: "REMOVE_MEMBER_FAILURE",
};
