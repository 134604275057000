import { lazy } from "react";

// const Landing = lazy(() => import("components/ExternalPages/Landing/Landing"));
const WaitList = lazy(() => import("components/ExternalPages/Landing/WaitList"));

// TO BE REMOVED

const Home = lazy(() => import("components/ExternalPages/Landing/Home"));
const About = lazy(() => import("components/ExternalPages/Landing/About"));
const Blog = lazy(() => import("components/ExternalPages/Landing/Blog"));
const Article = lazy(() => import("components/ExternalPages/Landing/Article"));
const Login = lazy(() => import("components/Auth/Login"));
const SignUp = lazy(() => import("components/Auth/SignUp"));
const ResetPassword = lazy(() => import("components/Auth/ResetPassword"));
const OnboardGFF = lazy(() => import("components/OnboardGFF"));
const ShipmentRequest = lazy(() => import("components/OnboardGFF/ShipmentRequest"));
const CreateQuote = lazy(() => import("components/OnboardGFF/CreateQuote"));
const Profile = lazy(() => import("components/ExternalPages/Profile"));
const RequestQuote = lazy(() => import("components/ExternalPages/RequestQuote"));
const AcceptRejectQuote = lazy(() => import("components/ExternalPages/AcceptRejectQuote"));
const Payment = lazy(() => import("components/ExternalPages/Payment"));
const PaymentSuccess = lazy(() => import("components/ExternalPages/PaymentSuccess"));
const Receipt = lazy(() => import("components/ExternalPages/Receipt"));
const MessagingLink = lazy(() => import("components/ExternalPages/MessagingLink"));
const Tracking = lazy(() => import("components/ExternalPages/Tracking"));
const MovementDetails = lazy(() => import("components/ExternalPages/Tracking/MovementDetails"));
const RateFreightForwarder = lazy(() => import("components/ExternalPages/RateFreightForwarder"));
const SearchTrackingNo = lazy(() => import("components/ExternalPages/searchTrackingNo"));
const Reviews = lazy(() => import("components/ExternalPages/Reviews"));
const Onboarding = lazy(() => import("components/Onboarding"));
const Dashboard = lazy(() => import("components/Dashboard"));
const Folders = lazy(() => import("components/Folders"));
const ShipmentCreation = lazy(() => import("components/ShipmentCreation"));
const ShipmentDetails = lazy(() => import("components/ShipmentDetails"));
const ShipmentList = lazy(() => import("components/Shipment/ShipmentList"));
const SingleQuote = lazy(() => import("components/ExternalPages/SingleQuote"));
const QuotesList = lazy(() => import("components/Quotes/QuotesList"));
const QuoteRequests = lazy(() => import("components/Quotes/QuoteRequests"));
const Settings = lazy(() => import("components/Settings"));
const CreateQuoteCustomer = lazy(() => import("components/Quotes/CreateQuoteCustomer"));
const Referral = lazy(() => import("components/Referral"));
const FAQs = lazy(() => import("components/ExternalPages/Landing/FAQs"));

// ffid: 6346b34755340800bea0af5c
// ship: 637f764bbc550700c03770f2
// shipEmpty: 63db7779da8c2400c16f3195
// longshipdetails: 6405e288132d7000e4daff4e

const routes = [
    // {
    //     path: "/beta",
    //     component: <Landing />,
    // },
    {
        path: "/waitlist",
        component: <WaitList />,
    },
    // TO BE REMOVED
    {
        path: "/",
        component: <Home />,
    },
    {
        path: "/about",
        component: <About />,
    },
    {
        path: "/blog",
        component: <Blog />,
    },
    {
        path: "/blog/:cat/:articleId",
        component: <Article />,
    },
    {
        path: "faqs",
        component: <FAQs />,
    },
    {
        path: "/login",
        component: <Login />,
    },
    {
        path: "/register",
        component: <SignUp />,
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
    },
    { name: "OnboardGFF Global Fright Forwarder", path: "/onboard-gff", component: <OnboardGFF /> },
    {
        name: "OnboardGFF Global Fright Forwarder Quote",
        path: "/quote-request",
        component: <ShipmentRequest />,
    },
    {
        name: "OnboardGFF Global Fright Forwarder Create Quote",
        path: "/create-quote",
        component: <CreateQuote />,
    },
    {
        name: "Profile",
        path: "profile/:ffid",
        component: <Profile />,
    },
    {
        name: "Request Quote",
        path: "request-quote/:ffid",
        component: <RequestQuote />,
    },
    {
        name: "Accept Reject Quote",
        path: "accept-reject-quote/:quoteId",
        component: <AcceptRejectQuote />,
    },
    {
        name: "Payment",
        path: "payment/:paymentId",
        component: <Payment />,
    },
    {
        name: "Payment Success",
        path: "payment-success/:paymentId",
        component: <PaymentSuccess />,
    },
    {
        name: "Receipt",
        path: "receipt/:id",
        component: <Receipt />,
    },
    {
        name: "Messaging Link",
        path: "messaging-link/:shipmentId",
        component: <MessagingLink />,
    },
    {
        name: "Tracking",
        path: "tracking/:shipmentId",
        component: <Tracking />,
    },
    {
        name: "Movement Tracking",
        path: "tracking/:shipmentId/:movementId",
        component: <MovementDetails />,
    },
    {
        name: "Freight Rate",
        path: "rate-freight-forwarder/:shipmentId",
        component: <RateFreightForwarder />,
    },
    {
        name: "Tracking Search",
        path: "search-tracking-number/:ffid",
        component: <SearchTrackingNo />,
    },
    {
        name: "Reviews",
        path: "reviews/:ffid",
        component: <Reviews />,
    },
    {
        name: "Onboarding",
        path: "onboarding",
        component: <Onboarding />,
    },
    {
        parent: "dashboard",
        name: "Dashboard",
        path: "home",
        component: <Dashboard />,
    },
    {
        parent: "dashboard",
        name: "Folders",
        path: "folders",
        component: <Folders />,
    },
    {
        parent: "dashboard",
        name: "Create Shipment",
        path: "shipment-creation/:id",
        component: <ShipmentCreation />,
    },
    {
        parent: "dashboard",
        name: "Shipment Details",
        path: "shipment/:shipmentId",
        component: <ShipmentDetails />,
    },
    {
        parent: "dashboard",
        name: "Shipment Details Tabs",
        path: "shipment/:shipmentId/:tab",
        component: <ShipmentDetails />,
    },
    {
        parent: "dashboard",
        name: "Shipment List",
        path: "shipments",
        component: <ShipmentList />,
    },
    {
        name: "Single Quote",
        path: "single-quote/:quoteId",
        component: <SingleQuote />,
    },
    {
        parent: "dashboard",
        name: "Quote List",
        path: "quotes",
        component: <QuotesList />,
    },
    {
        parent: "dashboard",
        name: "Quote Requests",
        path: "quote-requests",
        component: <QuoteRequests />,
    },
    {
        parent: "dashboard",
        name: "Settings",
        path: "settings",
        component: <Settings />,
    },
    {
        parent: "dashboard",
        name: "Create Quote For Customer",
        path: "create-quote/customer/:shipmentId",
        component: <CreateQuoteCustomer />,
    },
    {
        parent: "dashboard",
        name: "Referral",
        path: "referral",
        component: <Referral />,
    },
];

export default routes;
