export const quoteConstants = {
    REQUEST_QUOTE: "REQUEST_QUOTE",
    REQUESTED_QUOTE_SUCCESS: "REQUESTED_QUOTE_SUCCESS",
    REQUESTED_QUOTE_FAILURE: "REQUESTED_QUOTE_FAILURE",
    FETCHING_SINGLE_QUOTE: "FETCHING_SINGLE_QUOTE",
    FETCHING_SINGLE_QUOTE_SUCCESS: "FETCHING_SINGLE_QUOTE_SUCCESS",
    FETCHING_SINGLE_QUOTE_FAILURE: "FETCHING_SINGLE_QUOTE_FAILURE",
    ACCEPTING_SINGLE_QUOTE: "ACCEPTING_SINGLE_QUOTE",
    ACCEPTING_SINGLE_QUOTE_SUCCESS: "ACCEPTING_SINGLE_QUOTE_SUCCESS",
    ACCEPTING_SINGLE_QUOTE_FAILURE: "ACCEPTING_SINGLE_QUOTE_FAILURE",
    REJECTIING_SINGLE_QUOTE: "REJECTIING_SINGLE_QUOTE",
    REJECTIING_SINGLE_QUOTE_SUCCESS: "REJECTIING_SINGLE_QUOTE_SUCCESS",
    REJECTIING_SINGLE_QUOTE_FAILURE: "REJECTIING_SINGLE_QUOTE_FAILURE",
    NEGOTIATING_SINGLE_QUOTE: "NEGOTIATING_SINGLE_QUOTE",
    NEGOTIATING_SINGLE_QUOTE_SUCCESS: "NEGOTIATING_SINGLE_QUOTE_SUCCESS",
    NEGOTIATING_SINGLE_QUOTE_FAILURE: "NEGOTIATING_SINGLE_QUOTE_FAILURE",
    FETCH_SINGLE_QUOTE_REQUEST: "FETCH_SINGLE_QUOTE_REQUEST",
    FETCH_SINGLE_QUOTE_SUCCESS: "FETCH_SINGLE_QUOTE_SUCCESS",
    FETCH_SINGLE_QUOTE_FAILURE: "FETCH_SINGLE_QUOTE_FAILURE",
    CREATE_QUOTE_FOR_QUOTE_REQ_REQUEST: "CREATE_QUOTE_FOR_QUOTE_REQ_REQUEST",
    CREATE_QUOTE_FOR_QUOTE_REQ_SUCCESS: "CREATE_QUOTE_FOR_QUOTE_REQ_SUCCESS",
    CREATE_QUOTE_FOR_QUOTE_REQ_FAILURE: "CREATE_QUOTE_FOR_QUOTE_REQ_FAILURE",
    FETCH_QUOTES_REQUEST: "FETCH_QUOTES_REQUEST",
    FETCH_QUOTES_SUCCESS: "FETCH_QUOTES_SUCCESS",
    FETCH_QUOTES_FAILURE: "FETCH_QUOTES_FAILURE",
    FETCH_QUOTES_ANALYTICS_REQUEST: "FETCH_QUOTES_ANALYTICS_REQUEST",
    FETCH_QUOTES_ANALYTICS_SUCCESS: "FETCH_QUOTES_ANALYTICS_SUCCESS",
    FETCH_QUOTES_ANALYTICS_FAILURE: "FETCH_QUOTES_ANALYTICS_FAILURE",
    FETCH_EXTERNAL_QUOTES_REQUEST: "FETCH_EXTERNAL_QUOTES_REQUEST",
    FETCH_EXTERNAL_QUOTES_SUCCESS: "FETCH_EXTERNAL_QUOTES_SUCCESS",
    FETCH_EXTERNAL_QUOTES_FAILURE: "FETCH_EXTERNAL_QUOTES_FAILURE",
    FETCHING_OUTGOING_QUOTE_REQUEST: "FETCHING_OUTGOING_QUOTE_REQUEST",
    FETCH_OUTGOING_QUOTE_REQUEST_SUCCESS: "FETCH_OUTGOING_QUOTE_REQUEST_SUCCESS",
    FETCH_OUTGOING_QUOTE_REQUEST_FAILURE: "FETCH_OUTGOING_QUOTE_REQUEST_FAILURE",
    FETCHING_INCOMING_QUOTE_REQUEST: "FETCHING_INCOMING_QUOTE_REQUEST",
    FETCH_INCOMING_QUOTE_REQUEST_SUCCESS: "FETCH_INCOMING_QUOTE_REQUEST_SUCCESS",
    FETCH_INCOMING_QUOTE_REQUEST_FAILURE: "FETCH_INCOMING_QUOTE_REQUEST_FAILURE",
    FF_ACCEPTING_QUOTE_REQUEST: "FF_ACCEPTING_QUOTE_REQUEST",
    FF_ACCEPTING_QUOTE_SUCCESS: "FF_ACCEPTING_QUOTE_SUCCESS",
    FF_ACCEPTING_QUOTE_FAILURE: "FF_ACCEPTING_QUOTE_FAILURE",
    FF_REJECTING_QUOTE_REQUEST: "FF_REJECTING_QUOTE_REQUEST",
    FF_REJECTING_QUOTE_SUCCESS: "FF_REJECTING_QUOTE_SUCCESS",
    FF_REJECTING_QUOTE_FAILURE: "FF_REJECTING_QUOTE_FAILURE",
    QUOTE_REQUEST_ACCEPTANCE_REQUEST: "QUOTE_REQUEST_ACCEPTANCE_REQUEST",
    QUOTE_REQUEST_ACCEPTANCE_SUCCESS: "QUOTE_REQUEST_ACCEPTANCE_SUCCESS",
    QUOTE_REQUEST_ACCEPTANCE_FAILURE: "QUOTE_REQUEST_ACCEPTANCE_FAILURE",
    QUOTE_REQUEST_REJECT_REQUEST: "QUOTE_REQUEST_REJECT_REQUEST",
    QUOTE_REQUEST_REJECT_SUCCESS: "QUOTE_REQUEST_REJECT_SUCCESS",
    QUOTE_REQUEST_REJECT_FAILURE: "QUOTE_REQUEST_REJECT_FAILURE",
    RESET_QUOTE_SUCCESS_DATA: "RESET_QUOTE_SUCCESS_DATA",
    CREATE_QUOTE_TO_CUSTOMER_REQUEST: "CREATE_QUOTE_TO_CUSTOMER_REQUEST",
    CREATE_QUOTE_TO_CUSTOMER_SUCCESS: "CREATE_QUOTE_TO_CUSTOMER_SUCCESS",
    CREATE_QUOTE_TO_CUSTOMER_FAILURE: "CREATE_QUOTE_TO_CUSTOMER_FAILURE",
    SEND_QUOTE_REQ_FF_REQUEST: "SEND_QUOTE_REQ_FF_REQUEST",
    SEND_QUOTE_REQ_FF_SUCCESS: "SEND_QUOTE_REQ_FF_SUCCESS",
    SEND_QUOTE_REQ_FF_FAILURE: "SEND_QUOTE_REQ_FF_FAILURE",
    RESET_FF_ACCEPTING_REJECT_QUOTE_SUCCESS: "RESET_FF_ACCEPTING_REJECT_QUOTE_SUCCESS",
    RESET_ACCEPTING_REJECT_QUOTE_REQUEST_SUCCESS: "RESET_ACCEPTING_REJECT_QUOTE_REQUEST_SUCCESS",
    RESET_FETCH_SINGLE_QUOTE_SUCCESS: "RESET_FETCH_SINGLE_QUOTE_SUCCESS",
};
