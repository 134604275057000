export const chatConstants = {
    CUSTOMER_SHIPMENT_CHAT_REQUEST: "CUSTOMER_SHIPMENT_CHAT_REQUEST",
    CUSTOMER_SHIPMENT_CHAT_SUCCESS: "CUSTOMER_SHIPMENT_CHAT_SUCCESS",
    CUSTOMER_SHIPMENT_CHAT_FAILURE: "CUSTOMER_SHIPMENT_CHAT_FAILURE",
    SEND_CUSTOMER_SHIPMENT_CHAT_REQUEST: "SEND_CUSTOMER_SHIPMENT_CHAT_REQUEST",
    SEND_CUSTOMER_SHIPMENT_CHAT_SUCCESS: "SEND_CUSTOMER_SHIPMENT_CHAT_SUCCESS",
    SEND_CUSTOMER_SHIPMENT_CHAT_FAILURE: "SEND_CUSTOMER_SHIPMENT_CHAT_FAILURE",
    NEW_FF_CHAT: "NEW_FF_CHAT",
    NEW_CUSTOMER_CHAT: "NEW_CUSTOMER_CHAT",
    FF_SHIPMENT_CHAT_REQUEST: "FF_SHIPMENT_CHAT_REQUEST",
    FF_SHIPMENT_CHAT_SUCCESS: "FF_SHIPMENT_CHAT_SUCCESS",
    FF_SHIPMENT_CHAT_FAILURE: "FF_SHIPMENT_CHAT_FAILURE",
    SEND_FF_SHIPMENT_CHAT_REQUEST: "SEND_FF_SHIPMENT_CHAT_REQUEST",
    SEND_FF_SHIPMENT_CHAT_SUCCESS: "SEND_FF_SHIPMENT_CHAT_SUCCESS",
    SEND_FF_SHIPMENT_CHAT_FAILURE: "SEND_FF_SHIPMENT_CHAT_FAILURE",
    CLEAR_FF_CHAT: "CLEAR_FF_CHAT",
};
