export const blogConstants = {
    FETCH_ARTICLES_BY_TAG_REQUEST: "FETCH_ARTICLES_BY_TAG_REQUEST",
    FETCH_ARTICLES_BY_TAG_SUCCESS: "FETCH_ARTICLES_BY_TAG_SUCCESS",
    FETCH_ARTICLES_BY_TAG_FAILURE: "FETCH_ARTICLES_BY_TAG_FAILURE",
    FETCH_SINGLE_ARTICLE_REQUEST: "FETCH_SINGLE_ARTICLE_REQUEST",
    FETCH_SINGLE_ARTICLE_SUCCESS: "FETCH_SINGLE_ARTICLE_SUCCESS",
    FETCH_SINGLE_ARTICLE_FAILURE: "FETCH_SINGLE_ARTICLE_FAILURE",
    FETCH_BLOG_TAGS_REQUEST: "FETCH_BLOG_TAGS_REQUEST",
    FETCH_BLOG_TAGS_SUCCESS: "FETCH_BLOG_TAGS_SUCCESS",
    FETCH_BLOG_TAGS_FAILURE: "FETCH_BLOG_TAGS_FAILURE",
};
