import { configureStore } from "@reduxjs/toolkit";
import {
    auth,
    shipment,
    chat,
    quote,
    payment,
    app,
    subscription,
    notification,
    folder,
    blog,
} from "reducers";

export default configureStore({
    reducer: {
        auth,
        shipment,
        chat,
        quote,
        payment,
        app,
        subscription,
        notification,
        folder,
        blog,
    },
});
