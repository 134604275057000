export const notificationConstants = {
    FILTER_NOTIFICATIONS_REQUEST: "FILTER_NOTIFICATIONS_REQUEST",
    FILTER_NOTIFICATIONS_SUCCESS: "FILTER_NOTIFICATIONS_SUCCESS",
    FILTER_NOTIFICATIONS_FAILURE: "FILTER_NOTIFICATIONS_FAILURE",
    MARK_READ_REQUEST: "MARK_READ_REQUEST",
    MARK_READ_SUCCESS: "MARK_READ_SUCCESS",
    MARK_READ_FAILURE: "MARK_READ_FAILURE",
    MARK_ALL_READ_REQUEST: "MARK_ALL_READ_REQUEST",
    MARK_ALL_READ_SUCCESS: "MARK_ALL_READ_SUCCESS",
    MARK_ALL_READ_FAILURE: "MARK_ALL_READ_FAILURE",
};
