export const subscriptionConstants = {
    FETCH_PLAN_LISTING_REQUEST: "FETCH_PLAN_LISTING_REQUEST",
    FETCH_PLAN_LISTING_SUCCESS: "FETCH_PLAN_LISTING_SUCCESS",
    FETCH_PLAN_LISTING_FAILURE: "FETCH_PLAN_LISTING_FAILURE",
    FETCH_PLAN_BENEFITS_REQUEST: "FETCH_PLAN_BENEFITS_REQUEST",
    FETCH_PLAN_BENEFITS_SUCCESS: "FETCH_PLAN_BENEFITS_SUCCESS",
    FETCH_PLAN_BENEFITS_FAILURE: "FETCH_PLAN_BENEFITS_FAILURE",
    FETCH_SUBSCRIPTION_REQUEST: "FETCH_SUBSCRIPTION_REQUEST",
    FETCH_SUBSCRIPTION_SUCCESS: "FETCH_SUBSCRIPTION_SUCCESS",
    FETCH_SUBSCRIPTION_FAILURE: "FETCH_SUBSCRIPTION_FAILURE",
    END_SUBSCRIPTION_REQUEST: "END_SUBSCRIPTION_REQUEST",
    END_SUBSCRIPTION_SUCCESS: "END_SUBSCRIPTION_SUCCESS",
    END_SUBSCRIPTION_FAILURE: "END_SUBSCRIPTION_FAILURE",
};
